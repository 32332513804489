<template>
	<div :class="`${showManageSubscription ? '' : 'app flex-row align-items-center'}`">
		<div v-if="vueAppRunningProduction" class="banner-testing-mode"></div>
		<div v-if="vueAppRunningProduction" class="container-testing-mode">
			{{ FormMSG(268, 'Testing') }}
		</div>
		<div class="container">
			<div v-if="!forgotPassword && !showManageSubscription">
				<b-row v-if="showSuggestChrome" class="justify-content-center">
					<b-col md="8">
						<b-alert variant="warning" show dismissible>
							{{ FormMSG(250, 'Use') }} <strong>{{ FormMSG(251, 'Chrome browser') }}</strong> {{ FormMSG(252, 'for better use') }}.
						</b-alert>
					</b-col>
				</b-row>
				<b-row class="login justify-content-center">
					<b-col md="8" class="form">
						<!-- <loading :active.sync="isLoading" :is-full-page="true" /> -->
						<!-- <pre>#### {{ requiredAppVersion }} ####</pre> -->
						<b-card-group>
							<b-card
								no-body
								class="card-border-blue-light p-4"
								:class="`${!$screen.xl && !$screen.lg ? 'card-full-fill' : 'card-half-left-fill'}`"
							>
								<b-card-body>
									<div class="text-center">
										<img class="navbar-brand-full" src="img/brand/thegreenshot-brand.png" width="225" alt="TheGreenShot" />
										<h2 class="pt-3 pb-2">{{ FormMSG(101, ' Login') }}</h2>
										<!-- <p class="text-muted">
											{{ FormMSG(102, 'Sign In to your account') }}
										</p> -->
									</div>
									<b-form @submit.prevent="signin">
										<div :class="{ 'mb-4': !authentificationFailed }">
											<b-form-group v-if="!askNewPassword">
												<b-input-group class="mb-3">
													<b-input-group-prepend>
														<b-input-group-text>
															<component :is="getLucideIcon('User')" :color="ICONS.PERCENT.color" :size="18" />
														</b-input-group-text>
													</b-input-group-prepend>
													<b-form-input
														v-model="$v.email.$model"
														type="email"
														class="form-control"
														:class="{ 'border-gray': $v.email.$error === false, 'br-none': $v.email.$error === true }"
														:placeholder="FormMSG(103, 'Email address')"
														autocomplete="username email"
														:state="!$v.email.$error"
														@keyup.enter="handleKeyUpEnterEmail"
														:disabled="doubleAuthentication"
													/>
													<b-input-group-append v-if="$v.email.$error">
														<button
															class="btn-show-password border-red"
															type="button"
															v-b-tooltip.click.bottomleft="manageMessageEmailError($v.email)"
														>
															<AlertTriangle color="red" :size="16" />
														</button>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
											<label v-if="askNewPassword" class="text-muted">
												{{ FormMSG(1111, 'New password') }}
											</label>
											<b-form-group v-if="!askNewPassword">
												<b-input-group>
													<b-input-group-prepend>
														<b-input-group-text>
															<component :is="getLucideIcon('Lock')" :color="ICONS.PERCENT.color" :size="18" />
														</b-input-group-text>
													</b-input-group-prepend>
													<b-form-input
														v-model="$v.password.$model"
														:type="showPassword ? 'text' : 'password'"
														class="form-control br-none"
														:class="{ 'border-gray br-none': $v.password.$error === false, 'is-invalid': $v.password.$error }"
														:placeholder="FormMSG(104, 'Password')"
														:state="!$v.password.$error"
														:disabled="doubleAuthentication"
														@keyup.enter="handleKeyUpEnterPassword"
													/>
													<b-input-group-append>
														<button
															class="btn-show-password"
															v-if="!$v.password.$error"
															type="button"
															@click="showPassword = !showPassword"
														>
															<component
																v-if="!showPassword"
																:is="getLucideIcon('Eye')"
																:color="ICONS.PERCENT.color"
																:size="18"
															/>
															<component
																v-if="showPassword"
																:is="getLucideIcon('EyeOff')"
																:color="ICONS.PERCENT.color"
																:size="18"
															/>
														</button>
														<button
															v-if="$v.password.$error === true"
															class="btn-show-password border-red"
															style="border-left: none !important"
															type="button"
															v-b-tooltip.click.bottomleft="FormMSG(498, 'Password is required')"
														>
															<AlertTriangle color="red" :size="16" />
														</button>
													</b-input-group-append>
												</b-input-group>
												<!-- <legend
													v-if="doubleAuthentication"
													tabindex="-1"
													class="col-12 bv-no-focus-ring col-form-label mt-3"
													id="aria-email-validation"
												>
													{{ FormMSG(53, 'Enter email verfication code') }} :
												</legend> -->
												<b-input-group v-if="doubleAuthentication" style="margin-top: 30px">
													<b-input-group-prepend>
														<b-input-group-text>
															<component
																:is="getLucideIcon('Key')"
																:color="ICONS.PERCENT.color"
																:size="18"
																:stroke-width="2.25"
															/>
														</b-input-group-text>
													</b-input-group-prepend>
													<b-form-input
														v-model="otpCode"
														ref="otpCodeValidation"
														class="form-control br-none"
														:placeholder="FormMSG(9654154, 'Enter your code validation ...')"
													/>
													<b-input-group-append>
														<button
															v-b-tooltip.hover
															:title="FormMSG(90, 'Resend email verfication code')"
															class="btn-show-password"
															type="button"
															@click.prevent="sendValidationCode"
														>
															<component :is="getLucideIcon('RotateCcw')" :color="ICONS.PERCENT.color" :size="18" />
														</button>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
											<b-form-group v-else>
												<b-input-group>
													<b-input-group-prepend>
														<b-input-group-text>
															<component :is="getLucideIcon('Lock')" :color="ICONS.PERCENT.color" :size="18" />
														</b-input-group-text>
													</b-input-group-prepend>
													<b-form-input
														id="new-password"
														v-model="$v.newPassword.$model"
														:type="showNewPassword ? 'text' : 'password'"
														class="form-control br-none"
														:class="{ 'border-gray br-none': $v.newPassword.$error === false, 'is-invalid': $v.newPassword.$error }"
														autocomplete="off"
														autofocus
														:placeholder="FormMSG(104, 'Password')"
														@keyup="handleKeyUpNewPassword"
													/>
													<b-input-group-append>
														<button
															class="btn-show-password"
															v-if="!$v.newPassword.$error"
															type="button"
															@click="showNewPassword = !showNewPassword"
														>
															<component
																v-if="!showNewPassword"
																:is="getLucideIcon('Eye')"
																:color="ICONS.PERCENT.color"
																:size="18"
															/>
															<component
																v-if="showNewPassword"
																:is="getLucideIcon('EyeOff')"
																:color="ICONS.PERCENT.color"
																:size="18"
															/>
														</button>
														<button
															v-if="$v.newPassword.$error === true"
															class="btn-show-password border-red"
															style="border-left: none !important"
															type="button"
															v-b-tooltip.click.bottomleft="FormMSG(498, 'Password is required')"
														>
															<AlertTriangle color="red" :size="16" />
														</button>
													</b-input-group-append>
												</b-input-group>
												<div class="invalid-feedback" style="font-size: 0.925rem" v-if="$v.newPassword.$error">
													{{
														FormMSG(
															477,
															'Password must contain minimum 8 characters and have at least one Upper case, one Lower case, one digit and one special character.'
														)
													}}
												</div>
											</b-form-group>
										</div>
										<div
											class="w-100 text-center mb-3"
											style="color: red; font-size: 0.975rem"
											v-if="authentificationFailed && !askNewPassword"
										>
											{{ FormMSG(500, 'Your email or password is incorrect.') }}
										</div>
										<div class="w-100 text-center mb-3" style="color: red; font-size: 0.975rem" v-if="codeInvalid">
											{{ FormMSG(427845, 'Your validation code is incorrect.') }}
										</div>
										<div v-if="askNewPassword">
											<label class="text-muted">
												{{ FormMSG(1112, 'Password verification') }}
											</label>
											<b-input-group class="mb-4">
												<b-input-group-prepend>
													<b-input-group-text>
														<component :is="getLucideIcon('Lock')" :color="ICONS.PERCENT.color" :size="18" />
													</b-input-group-text>
												</b-input-group-prepend>
												<b-form-input
													v-model="passwordVerification"
													type="password"
													class="form-control br-none"
													:placeholder="FormMSG(1113, 'Re-enter Password')"
													@keyup="handleVerificationNewPassword"
													@copy.prevent
													@paste.prevent
												/>
												<div class="invalid-feedback" style="font-size: 0.925rem" v-if="isNewPasswordIdenticalInvalid">
													{{ FormMSG(981, 'The two passwords must be the same.') }}
												</div>
											</b-input-group>
										</div>
										<div v-if="askNewPassword && queryType.NU" class="mb-3">
											<b-form-checkbox :value="true" :unchecked-value="false" @change="handleChangeCheckTermsOfService">
												{{ FormMSG(81, 'I accept') }}
												<span class="false-link" @click="handleClickTermsOfService">{{
													FormMSG(82, "TheGreenshot's Terms of Service")
												}}</span>
											</b-form-checkbox>
										</div>
										<b-row>
											<b-col md="12">
												<b-button
													style="width: 100%"
													type="submit"
													variant="primary"
													class="px-4"
													:disabled="loginDisabled() || waitProcessSignin || waitingDoubleAuthentication()"
													v-if="!askNewPassword"
												>
													<div class="d-flex justify-content-center align-items-center">
														<b-spinner v-if="waitProcessSignin" label="Loading..." small></b-spinner>
														<div class="pl-2" style="margin-top: 1px">{{ FormMSG(112, 'Login') }}</div>
													</div>
												</b-button>
												<b-button
													style="width: 100%"
													type="button"
													variant="primary"
													:disabled="$v.newPassword.$error || passwordVerification.trim() === '' || disabledChangePassword"
													@click="handleNewPassword"
													class="px-4"
													v-else
												>
													<div class="d-flex justify-content-center align-items-center">
														<b-spinner v-if="waitProcessSignin" label="Loading..." small></b-spinner>
														<div class="pl-2" style="margin-top: 1px">{{ FormMSG(942, 'Change Password') }}</div>
													</div>
												</b-button>
											</b-col>
											<b-col v-if="showRegisterOption" md="12" class="d-lg-none">
												<b-button style="width: 100%" class="active mt-3" variant="secondary" @click="registerNow">
													{{ FormMSG(107, 'Register Now!') }}
												</b-button>
											</b-col>
											<b-col v-if="!askNewPassword" class="text-right" md="12">
												<b-button
													style="width: 100%"
													variant="link"
													class="px-0"
													:disabled="waitProcessSignin || requiredAppVersion !== null"
													@click="handleClickForgotPassword"
												>
													{{ FormMSG(105, 'Forgot password?') }}
												</b-button>
											</b-col>
										</b-row>
									</b-form>
								</b-card-body>
							</b-card>
							<b-card
								v-if="showRegisterOption"
								no-body
								class="card-border-blue-light text-white py-5 d-md-down-none"
								style="width: 44%; border-radius: 0 8px 8px 0 !important; background-color: rgba(0, 160, 156, 0.84) !important"
							>
								<b-card-body class="text-center">
									<div style="letter-spacing: 0.05rem">
										<h2 class="pb-2">{{ FormMSG(111, 'Sign up') }}</h2>
										<p>
											{{
												FormMSG(
													106,
													'Sign up now to join and start working with TheGreenShot, track your timesheets, expenses, travels... and your carbon footprint.'
												)
											}}
										</p>
										<b-button
											variant="primary"
											class="active mt-3 px-4"
											:disabled="waitProcessSignin || requiredAppVersion !== null"
											@click="registerNow"
										>
											{{ FormMSG(107, 'Register Now!') }}
										</b-button>
									</div>
								</b-card-body>
							</b-card>
						</b-card-group>
					</b-col>
					<b-modal
						header-class="header-class-modal-doc-package"
						v-model="successModal"
						class="modal-success"
						ok-variant="success"
						ok-only
						:title="FormMSG(108, 'Success!')"
						@ok="successModal = false"
					>
						{{ FormMSG(109, '  An email has been sent to you. Open it and click on the provided link to reset your password.') }}
					</b-modal>
					<b-modal
						header-class="header-class-modal-doc-package"
						v-model="showModalSuccessRegistration"
						class="modal-success"
						ok-variant="success"
						ok-only
						no-close-on-backdrop
						no-close-on-esc
						:title="FormMSG(108, 'Success!')"
						@ok="handleOkModalSuccessRegistration"
						@hidden="handleOkModalSuccessRegistration"
					>
						<p>
							{{ messageModalSuccessRegistration }}
						</p>
					</b-modal>
					<b-modal
						header-class="header-class-modal-doc-package"
						v-model="showSubscriptionNotOrder"
						class="modal-danger"
						ok-variant="danger"
						ok-only
						no-close-on-backdrop
						no-close-on-esc
						:title="FormMSG(140, 'Subscription Error!')"
						@ok="showSubscriptionNotOrder = false"
						@hidden="showSubscriptionNotOrder = false"
					>
						<p>
							{{
								FormMSG(
									141,
									'Your subscription is not in order, please call support to verify. All Solo+ projects are deactivated and your account is changed to Solo Free until your subscription returns to normal.'
								)
							}}
						</p>
					</b-modal>
					<b-modal
						header-class="header-class-modal-doc-package"
						:title="FormMSG(1282, 'Validation code')"
						class="modal-success"
						v-model="showModalInfoCode"
						@ok="closeModalInfoCode"
						ok-variant="success"
						ok-only
					>
						{{ messageModalInfoCode }}
					</b-modal>
				</b-row>
			</div>
			<div v-if="forgotPassword">
				<account-recovery @account-recovery:next-step="handleAccountRecoveryNextStep" @cancel-recovery="cancelRecovery" />
			</div>
			<error-app-version v-model="isErrorAppVersion" :is-home="false" :last-version="version" @error-app-version-modal:closed="onErrorAppVersionClosed" />
			<error-session-expired v-model="isSessionExpired" @error-session-expired-modal:closed="isSessionExpired = false" />
		</div>
	</div>
</template>

<script>
// import gql from 'graphql-tag';
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import LanguageMessages from '@/mixins/languageMessages';
import { getParamVersion } from '@/shared/version';
import { getUserRequestInfo, getNewPasswordRequestInfo } from '@/cruds/users.crud';
import { isNil, passowrdValid } from '~utils';
import { Eye, EyeOff, AlertTriangle } from 'lucide-vue';
import AccountRecovery from '@/components/Login/AccountRecovery';
import SuggestChromeMixin from '@/mixins/suggestChrome.mixin';
import { getUserLastProject } from '@/cruds/project.crud';
import { getLicenseRequest } from '@/cruds/licenseRequest.crud';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import GlobalMixin from '@/mixins/global.mixin';
import ErrorAppVersion from '@/components/Modals/ErrorAppVersionModal';
import ErrorSessionExpired from '@/components/Modals/ErrorSessionExpiredModal';
import { signinService, verifyRecaptcha, emailValidationCode } from '@/cruds/registration.crud';
import { DEVICE } from '@/shared/constants';

export default {
	name: 'Login',
	mixins: [LanguageMessages, SuggestChromeMixin, validationMixin, GlobalMixin],
	components: {
		Loading,
		Eye,
		EyeOff,
		AlertTriangle,
		AccountRecovery,
		ErrorAppVersion,
		ErrorSessionExpired // VueCroppie
	},
	data() {
		return {
			doubleAuthentication: false,
			otpCode: null,
			licenseId: null,
			tokenFictif: null,
			showModalInfoCode: false,
			messageModalInfoCode: '',
			recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
			skipVerifyRecaptcha: process.env.VUE_APP_SKIP_RECAPTCHA_VERIFICATION,
			isLoading: false,
			successModal: false,
			startSoloModal: false,
			email: '',
			urlXid: null,
			askNewPassword: false,
			showRegisterOption: true,
			password: '',
			passwordVerification: '',
			newPassword: '',
			termsOfService: false,
			userRequest: {
				userName: '',
				userFirstName: '',
				email: '',
				userId: 0,
				validated: 0,
				xid: ''
			},
			licenseRequest: {
				userName: '',
				userFirstName: '',
				email: '',
				userId: 0,
				validated: 0,
				xid: '',
				requestType: -1
			},
			showModalSuccessRegistration: false,
			messageModalSuccessRegistration: '',
			queryType: {
				NL: false,
				NP: false,
				NU: false
			},
			showPassword: false,
			showPasswordVerification: false,
			forgotPassword: false,
			showSubscriptionNotOrder: false,
			showManageSubscription: false,
			projectForSubscription: null,
			myProfileForSubscription: {
				id: 0,
				name: '',
				firstName: '',
				email: '',
				language: 0
			},
			waitProcessSignin: false,
			requiredAppVersion: null,
			isErrorAppVersion: false,
			isSessionExpired: false,
			version: '',
			countAppVersionErrorLoaded: 0,
			DEVICE,
			authentificationFailed: false,
			showNewPassword: false,
			isNewPasswordIdenticalInvalid: false,
			disabledChangePassword: false,
			codeInvalid: false
		};
	},
	validations: {
		email: { required, email },
		password: { required },
		newPassword: { required, passowrdValid }
	},
	created() {
		this.initRecaptcha();
		if (this.$route.query.se) this.isSessionExpired = true;
	},
	async mounted() {
		if (this.$cookies.isKey('emailRegister')) {
			this.email = this.$cookies.get('emailRegister');
		}

		// check if paramater present and get userRequest information.
		let validToken;
		const paramLoaded = await this.checkUrlParameters();

		if (paramLoaded) {
			validToken = false;
		} else {
			validToken = store.isTokenValid();
		}

		const requiredAppVersion = this.$store.getters['header/getRequiredAppVersion'];

		if (validToken === true) {
			this.requiredAppVersion = null;

			if (isNil(requiredAppVersion)) {
				this.$store.dispatch('header/setErrorAppVersion', null);
				this.$router.push('/my-dashboard?v=' + getParamVersion()).catch(() => {});
			} else {
				this.version = requiredAppVersion;
				this.requiredAppVersion = requiredAppVersion;
				// this.$router.go(0);
				this.isErrorAppVersion = true;
				if (this.detectDevice === DEVICE.WEB) {
					this.$router.replace(`/pages/login?${getParamVersion()}`);
					this.$store.dispatch('header/setCountAppVersionErrorLoaded');
				}
			}
		} else {
			if (!isNil(requiredAppVersion)) {
				this.version = requiredAppVersion;
				this.requiredAppVersion = requiredAppVersion;
				// this.$router.go(0);
				this.isErrorAppVersion = true;
				if (this.detectDevice === DEVICE.WEB) {
					this.$router.replace(`/pages/login?${getParamVersion()}`);
					this.$store.dispatch('header/setCountAppVersionErrorLoaded');
				}
			}
		}

		/**
		 * Pour tester si le logout a été forcé pour cause d'erreur d'abonnement
		 * C'est dans le defaultContainer qu'il y a l'écoute de l'abonnement
		 */
		if (store.state.subscriptionNotOrder) {
			this.showSubscriptionNotOrder = true;
			delete store.state.subscriptionNotOrder;
		}

		/**
		 * Pour vider les caches de navigation
		 */
		if (!validToken) {
			if (this.getNavItems[0]) await this.mutateNavItems({ isUpdate: false, navs: [] });
			const currentProject = store.getCurrentProjectConfig();
			if (currentProject) localStorage.removeItem('current-project');
			const savedNoTranslation = store.getSavedNoTranslationMenus();
			if (savedNoTranslation) localStorage.removeItem('saved-no-trans-menus');
		}
	},
	computed: {
		...mapGetters({
			getSubscriptionViaLogin: 'subscription/getSubscriptionViaLogin',
			getSubscriptionLicenseRequest: 'subscription/getSubscriptionLicenseRequest',
			getNavItems: 'nav-items/getNavItems'
		}),
		vueAppRunningProduction() {
			return process.env.VUE_APP_RUNNING_MODE !== 'PROD';
		},
		detectDevice() {
			let device = DEVICE.WEB;

			if (this.$isIOSDevice()) {
				device = DEVICE.IOS;
			} else if (this.$isAndroidDevice()) {
				device = DEVICE.ANDROID;
			}

			return device;
		}
	},
	methods: {
		...mapActions({
			updateSubscriptionViaLogin: 'subscription/updateSubscriptionViaLogin',
			updateSubscriptionLicenseRequest: 'subscription/updateSubscriptionLicenseRequest',
			resetSubscriptionData: 'subscription/resetSubscriptionData',
			updateSubscriptionProjectId: 'subscription/updateSubscriptionProjectId',
			mutateNavItems: 'nav-items/mutateNavItems'
		}),
		async sendValidationCode(e) {
			const result = await emailValidationCode({
				email: this.email,
				language: 0
			});
			this.licenseId = result.id;
			this.openModalInfoCode();
		},
		openModalInfoCode() {
			this.showModalInfoCode = true;
			this.messageModalInfoCode = this.FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + this.email;
			this.otpCode = null;
		},
		closeModalInfoCode() {
			this.showModalInfoCode = false;
			this.$nextTick(async () => {
				await this.$refs['otpCodeValidation'].focus();
			});
		},
		async onVerifyRecaptcha(e) {
			e.preventDefault();
			const result = await new Promise((resolve, reject) => {
				grecaptcha.enterprise.ready(async () => {
					const token = await grecaptcha.enterprise.execute(this.recaptchaSiteKey, { action: 'LOGIN' });
					verifyRecaptcha(token).then((res) => {
						if (res.data.VerifyRecaptcha) {
							resolve(true);
						} else {
							reject(false);
						}
					});
				});
			});
			return result;
		},
		manageMessageEmailError(emailValidation) {
			if (emailValidation.email === false) {
				return this.FormMSG(458, 'Email not valid');
			}
			if (emailValidation.required === false) {
				return this.FormMSG(459, 'Email is required');
			}
		},
		async handleKeyUpEnterEmail(e) {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				await this.signin(e);
			}
		},
		async handleKeyUpEnterPassword(e) {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				await this.signin(e);
			}
		},
		handleClickTermsOfService() {
			this.termsOfService = true;
			if (parseInt(this.userRequest.language) === 1) {
				window.open('https://www.thegreenshot.green/fr/termes-et-conditions', '_blank');
			} else {
				window.open('https://www.thegreenshot.green/terms-and-conditions', '_blank');
			}
		},
		handleChangeCheckTermsOfService(payload) {
			this.termsOfService = payload;
		},
		async handleSubscriptionViaLoginClose() {
			document.location.href = '/';
		},
		async handleAccountRecoveryNextStep(payload) {
			this.forgotPassword = false;
			const urlCheckStatus = await this.checkUrlParameters({
				NP: payload.xid
			});
			if (urlCheckStatus) this.$router.replace({ path: '/pages/login', query: { NP: payload.xid } });
		},
		cancelRecovery() {
			this.forgotPassword = false;
		},
		handleClickForgotPassword() {
			this.forgotPassword = true;
		},
		handleOkModalSuccessRegistration() {
			this.showModalSuccessRegistration = false;
			this.$router.push('/my-dashboard?v=' + getParamVersion());
		},
		loginDisabled() {
			// check if password and email are entered and eventually if password and passwordVerification are equal
			let ret = true;
			const condition = this.queryType.NU
				? eval(
						'this.password.length > 0 && this.passwordVerification.length >0 && this.password === this.passwordVerification && this.email.length>0 && this.termsOfService'
				  )
				: eval('this.password.length > 0 && this.passwordVerification.length >0 && this.password === this.passwordVerification && this.email.length>0');

			if (this.askNewPassword) {
				if (condition) {
					ret = false;
				}
			} else {
				//console.log( "qsdfqsf",this.password.length)
				if (this.password.length > 0 && this.email.length > 0) {
					ret = false;
				}
			}
			return ret;
		},
		waitingDoubleAuthentication() {
			return (
				this.doubleAuthentication === true &&
				this.email.length > 0 &&
				this.password.length > 0 &&
				(this.otpCode === null || (this.otpCode !== null && this.otpCode.length !== 6))
			);
		},
		async checkUrlParameters(params = null) {
			//let urlParams = new URLSearchParams(window.location.search);
			let urlParams = this.$route.query;

			if (params !== null) {
				urlParams = { ...urlParams, ...params };
			}

			let xid = null;
			if (!isNil(urlParams.NU)) {
				// console.log("urlParams:urlParams.has", urlParams.has('NU')); // true
				xid = urlParams.NU;
				this.queryType.NU = true;
				if (!isNil(xid)) {
					//  console.log("xid:",xid);
					this.userRequest = await getUserRequestInfo(xid);
					this.email = this.userRequest.email;
					this.password = '';
					if (this.userRequest.validated === 0) {
						this.urlXid = this.userRequest.xid;
						// if parameter is passed, and not yet validated then force signin
						//console.log("in mounted this.userRequest :",this.userRequest);
						this.showRegisterOption = false;
						if (this.userRequest.userId === 0 || this.userRequest.requestType === 1) {
							this.askNewPassword = true;
							this.showRegisterOption = false;
							// this.email = this.userRequest.email;
							// console.log('in mounted this.email :', this.email);
						}

						return true;
					}
				}
			} else if (!isNil(urlParams.NP)) {
				// new password request
				xid = urlParams.NP;
				this.queryType.NP = true;
				if (!isNil(xid)) {
					this.licenseRequest = await getNewPasswordRequestInfo(xid);
					this.showRegisterOption = false;
					this.email = this.licenseRequest.email;
					if (this.licenseRequest.validated === 0 && this.licenseRequest.requestType === 1) {
						this.askNewPassword = true;
						this.urlXid = xid;
					}
				}

				return true;
			} else if (!isNil(urlParams.NL)) {
				// new license request
				xid = urlParams.NL;
				this.queryType.NL = true;
				if (!isNil(xid)) {
					const newLR = await getNewPasswordRequestInfo(xid);
					this.showRegisterOption = false;
					this.email = newLR.email;
					if (newLR.validated === 0 && newLR.requestType === 0) {
						this.askNewPassword = true;
						this.licenseRequest = newLR;
						this.urlXid = xid;
					}
				}

				return true;
			} else if (!isNil(urlParams.MS)) {
				await this.manageStoreSubscription(urlParams);
			}

			return false;
		},
		async signin(e) {
			try {
				this.waitProcessSignin = true;
				let result = true;
				//if (!this.$isPwa() && this.$screen.width > 576) {
				if (this.skipVerifyRecaptcha == 'false') {
					result = await this.onVerifyRecaptcha(e);
				}
				//}
				if (result) {
					this.$store.dispatch('header/setErrorAppVersion', null);

					this.isLoading = true;

					let urXid = this.$route.query && this.$route.query.IU ? this.$route.query.IU : null;

					try {
						const signinResult = await signinService(
							this.email,
							this.password,
							this.urlXid,
							urXid,
							false,
							this.otpCode,
							this.licenseId,
							this.tokenFictif
						);

						this.isLoading = false;

						if (this.licenseRequest.requestType === 0) {
							// new license request for single user or for production or for SME or for Agency
							if (this.licenseRequest.userTypeForFilm !== 5) {
								this.messageModalSuccessRegistration = this.FormMSG(201, 'Start your trial license of TheGreenShot for 15 days');
								this.showModalSuccessRegistration = true;
							} else {
								//this.startSoloModal = true;
								this.messageModalSuccessRegistration = this.FormMSG(
									202,
									'TheGreenShot: Start your FREE  license for 1 project - 1 user. Check all the features in your project configuration screen'
								);
								this.showModalSuccessRegistration = true;
							}
						}

						this.tokenFictif = signinResult.data.signIn;
						let token = store.getTokenObject(signinResult.data.signIn);

						/**
						 * Little confusion after modified password,
						 * because of double check
						 * issue : https://app.clickup.com/t/86bzkfrtw
						 */
						if (token.TokenData.CheckOTP === true) {
							this.doubleAuthentication = true;
							this.licenseId = token.TokenData.LicenseID;
							this.waitProcessSignin = false;
							this.openModalInfoCode();
						} else {
							const res = await store.onNewToken(this.$apollo.provider.defaultClient, signinResult.data.signIn);
							if (res) {
								this.authentificationFailed = false;
								const scriptRecaptcha = document.getElementById('recaptcha-script');
								if (scriptRecaptcha) {
									document.head.removeChild(scriptRecaptcha);
									const recaptchaBadge = document.querySelector('.grecaptcha-badge');
									if (recaptchaBadge) {
										recaptchaBadge.style.display = 'none';
									}
								}
								this.redirectTo();
							}
						}
					} catch (error) {
						this.isLoading = false;
						this.waitProcessSignin = false;
						this.codeInvalid = false;
						if (error.networkError && error.networkError.statusCode !== 505) {
							const message = this.FormMSG(127, 'Your computer does not seem to be connected to the internet');
							this.$bvToast.toast(message, {
								autoHideDelay: 15000,
								variant: 'dark',
								solid: true,
								toaster: 'b-toaster-bottom-left',
								noCloseButton: true,
								toastClass: `toast-network`,
								appendToast: false
							});
						} else if (error.graphQLErrors.length > 0) {
							const status = error.graphQLErrors[0].status;
							const message = error.graphQLErrors[0].message;
							if (status === 401 && message === 'Email or password invalid') {
								this.authentificationFailed = true;
								this.waitProcessSignin = false;
								this.askNewPassword = false;
							} else if (status === 401 && message === 'invalid code') {
								this.codeInvalid = true;
								this.waitProcessSignin = false;
							}
						}
						// console.log('Got error: ' + JSON.stringify(error));
						this.requiredAppVersion = this.getAppVersionError(error);
						this.openErrorAppVersionModal();
					}
				} else {
					this.createToastForMobile(
						this.FormMSG(5, 'Error'),
						this.FormMSG(84551, 'An error was occured during logged in, please contact your administrator.'),
						'',
						'danger'
					);
					this.waitProcessSignin = false;
				}
			} catch (e) {
				this.createToastForMobile(
					this.FormMSG(5, 'Error'),
					this.FormMSG(84551, 'An error was occured during logged in, please contact your administrator.'),
					'',
					'danger'
				);
			}
		},
		initRecaptcha() {
			//if (!this.$isPwa() && this.$screen.width > 576) {
			if (this.skipVerifyRecaptcha == 'false') {
				const scriptRecaptcha = document.createElement('script');
				scriptRecaptcha.setAttribute('src', 'https://www.google.com/recaptcha/enterprise.js?render=' + this.recaptchaSiteKey);
				scriptRecaptcha.async = true;
				scriptRecaptcha.id = 'recaptcha-script';
				document.head.appendChild(scriptRecaptcha);
			}
			//}
		},
		async manageStoreSubscription(urlParams) {
			let xid = urlParams.MS;
			let resultGetLicenseRequest = null;
			let resultGetUserLastProject = null;

			if (_.isEmpty(this.getSubscriptionLicenseRequest)) {
				resultGetLicenseRequest = await getLicenseRequest(xid);
				this.updateSubscriptionLicenseRequest(resultGetLicenseRequest);
			} else {
				resultGetLicenseRequest = this.getSubscriptionLicenseRequest;
			}

			resultGetUserLastProject = await getUserLastProject(parseInt(resultGetLicenseRequest.userId));

			this.updateSubscriptionProjectId(resultGetUserLastProject.id);

			this.projectForSubscription = resultGetUserLastProject;

			this.myProfileForSubscription.id = resultGetLicenseRequest.userId;
			this.myProfileForSubscription.name = resultGetLicenseRequest.userName;
			this.myProfileForSubscription.firstName = resultGetLicenseRequest.userFirstName;
			this.myProfileForSubscription.email = resultGetLicenseRequest.email;
			this.myProfileForSubscription.language = resultGetLicenseRequest.language;

			this.showManageSubscription = true;
		},
		redirectTo() {
			this.resetSubscriptionData();
			if (this.$cookies.isKey('emailRegister')) {
				this.$cookies.remove('emailRegister');
			}
			if (this.queryType.NL) {
				this.$router.replace({ query: null });
			} else {
				this.$router.push('/my-dashboard?v=' + getParamVersion()).catch(() => {});
				return;
			}
		},
		registerNow() {
			this.$router.push('/pages/register');
		},
		getAppVersionError(error) {
			if (error.networkError && +error.networkError.statusCode === 505) {
				return error.networkError.bodyText.trim();
			}

			return null;
		},
		openErrorAppVersionModal() {
			if (!isNil(this.requiredAppVersion)) {
				this.isErrorAppVersion = true;
				this.version = this.requiredAppVersion;
				this.$store.dispatch('header/setErrorAppVersion', this.version);
				if (this.detectDevice === DEVICE.WEB) {
					this.$router.replace(`/pages/login?${getParamVersion()}`);
					this.$store.dispatch('header/setCountAppVersionErrorLoaded');
				}
			}
		},
		async onErrorAppVersionClosed() {
			this.isErrorAppVersion = false;
			if (this.detectDevice === DEVICE.WEB) {
				this.countAppVersionErrorLoaded = this.$store.getters['header/getCountAppVersionErrorLoaded'];
				if (this.countAppVersionErrorLoaded > 1) {
					this.isErrorAppVersion = false;
					this.$store.dispatch('header/resetCountAppVersionErrorLoaded');
					this.$router.go(0);
				}
			}
		},
		handleKeyUpNewPassword() {
			this.showNewPassword = false;
		},
		async handleNewPassword(e) {
			this.isNewPasswordIdenticalInvalid = false;

			if (this.newPassword !== this.passwordVerification) {
				this.isNewPasswordIdenticalInvalid = true;

				return;
			}

			const message = this.FormMSG(7898544, 'Your password has been changed successfully. And you will be redirected to the login page.');
			const success = await new Promise((resolve) => {
				this.createToastForMobile(this.FormMSG(658, 'Success'), message, '', 'success');
				this.disabledChangePassword = true;
				this.waitProcessSignin = true;

				setTimeout(() => {
					resolve(true);
				}, 2000);
			});

			if (success) {
				// this.password = this.newPassword;
				//await this.signin(e);
				this.$router.push('/pages/login').catch(() => {});
				this.loginInit();
				this.$v.$reset();
			}
		},
		loginInit() {
			this.email = '';
			this.password = '';
			this.askNewPassword = false;
			this.waitProcessSignin = false;
			this.showRegisterOption = true;
		},
		handleVerificationNewPassword() {
			this.showNewPassword = false;
		}
	},
	watch: {
		newPassword: {
			handler(value) {
				if (value.trim() !== '') {
					this.$v.$touch();
				}
			},
			immediate: true,
			deep: true
		}
	}
};
</script>

<style lang="scss">
.banner-testing-mode {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 3px;
	background-color: #f66;
}

.container-testing-mode {
	position: absolute;
	top: 3px;
	right: 0;
	padding: 15px;
	background-color: #f66;
	color: white;
	font-size: 13px;
	font-weight: bolder;
	border-radius: 0px 0px 0px 15px;
}

.card-half-left-fill {
	border-radius: 8px 0 0 8px !important;
}

.card-full-fill {
	border-radius: 8px !important;
}

.toast-network {
	background-color: #000 !important;
	color: #fff !important;
	font-size: 0.975rem !important;
}

#new-password .is-invalid {
	border-color: #ea4e2c !important;
}
</style>
